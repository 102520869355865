import React, { FormEvent } from 'react';
import './Admin.css';
import axios from 'axios';
import { config } from '../../config';

const axiosInstance = axios.create({
    baseURL: `https://status-page-web-push-notifications-worker-poc.pdt-apontes.workers.dev`,
})

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';

export function Admin() {

    const sendNotification = (f: any) => {

        const topic = f.target.elements['topic'].value;
        const title = f.target.elements['title'].value;
        const body = f.target.elements['body'].value;

        const url = `/publish_api/v1/instances/${config.pusherInstanceId}/publishes`;

        const data = {
            interests: [topic],
            web: {
                notification: {
                    title,
                    body,
                },
            },
        };

        axiosInstance.post(url, data, )
    }

    return (
        <div className="Admin">
          <header className="Admin">
            <h1>WebPush Notifications</h1>
          </header>

            <form onSubmit={(f) => {
                f.preventDefault();
                sendNotification(f);
            }}>
                <h3>Send notification</h3>
                <div>
                    <label>topic: </label>
                    <input name="topic" defaultValue="public"/>
                </div>
                <div>
                    <label>title: </label>
                    <input name="title" defaultValue="Company - Status Page"/>
                </div>
                <div>
                    <label>body: </label>
                    <input name="body" defaultValue="[SEV-1] Site is down!"/>
                </div>
                <input type="submit" />
            </form>

        </div>
    );
}
