export function loadEnvVar(name){
    const val = process.env[name];

    if(val === undefined)
        throw new Error(`Environment variable ${name} undefined`)

    return val;
}

export const config = {
    pusherInstanceId: loadEnvVar('REACT_APP_PUSHER_INSTANCE_ID'),
};
