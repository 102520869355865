import React, { useEffect, useRef, useState } from 'react';
import { config } from '../../config';
import logo from '../../logo.svg';
import './App.css';


function initPusher(stateUpdater: (a:any) => void){
    const error = (...params : any[]) => {
        console.error(params);
        stateUpdater({
            status: "error",
            error: params.map(e => e instanceof Error    
                ? {msg: e.message, ...e}
                : e
            ),
        })
    }

    try {
        /* eslint: ignore */
        const PusherPushNotifications = require("@pusher/push-notifications-web");

        const beamsClient = new PusherPushNotifications.Client({
            instanceId: config.pusherInstanceId,
        });

        beamsClient.start()
            .then(() => beamsClient.addDeviceInterest('debug-hello'))
            .then(() => beamsClient.addDeviceInterest('debug-world'))
            .then(() => console.log('Successfully registered and subscribed!'))
            .then(() => stateUpdater({status: "ok", msg: "READY!"}))
            .catch(error);

        return beamsClient;
    }
    catch(e:any){
        error("catch", {msg: e.message, ...e});
        return null;
    }
}


const Subscribe = () => {
    const pusherRef = useRef(null as any);
    const [pusherState, setPusherState] = useState("Initializing..." as any);

    useEffect(() => {
        pusherRef.current = initPusher(setPusherState);
    }, [])

    return (
        <div>
            <h1>Subscribe WebPush</h1>
            <pre>Pusher Status: {JSON.stringify(pusherState)}</pre>

            <form onSubmit={(f:any) => {
                f.preventDefault();
                const topic = f.target.elements['topic'].value
                pusherRef.current?.addDeviceInterest(topic)
                    .then(alert(`topic:${topic} subscribed`))
                    .catch((e:any) => alert('error: ' + JSON.stringify(e)))
            }}>
                <h3>Subscribe</h3>
                <div>
                    <label>topic</label>
                    <input name="topic" defaultValue="public" />
                </div>
                <input type="submit"></input>
            </form>

            <form onSubmit={(f:any) => {
                f.preventDefault();
                const topic = f.target.elements['topic'].value
                pusherRef.current?.removeDeviceInterest(topic)
                    .then(alert(`topic:${topic} UNsubscribed`))
                    .catch((e:any) => alert('error: ' + JSON.stringify(e)))
            }}>
                <h3>UNsubscribe</h3>
                <div>
                    <label>topic</label>
                    <input name="topic" defaultValue="public" />
                </div>
                <input type="submit"></input>
            </form>
        </div>
    )
}

export function App() {
  return (
    <div className="App">
      <header className="App">
        <h1>WebPush Notifications</h1>
      </header>
      <Subscribe />
    </div>
  );
}

export default App;
